/*This is the CSS stylesheet for the fonts of the website*/


h1,h2,h3{
    font-family: 'Helvetica Compressed', sans-serif;
    color: #007bff;
}

header{
    font-family: 'Helvetica Compressed', sans-serif;
    color: #e5e5e5;
}


.TopOfPage h1{
    font-size: 4em;
    margin: 0;
    color: #e5e5e5;
}
    nav ul {
        list-style: none;
        padding: 0;
    }

    nav ul li{
        display: inline;
        margin: 0 15px;
    }
