.navbar ul{
    justify-content: center;
    list-style-type: none;
    display: flex;
    gap: 15px;
    padding: 10px;
}

.navbar li a{
    text-decoration: none;
    color: #cecece;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background 0.3s;
}

.navbar li a.active{
    background: #007bff;
    color: #fff;
}

.navbar li a:hover{
    background: #007bff;
    color: #fff;
}