.BlogTopOfPage{
    text-align: center;
    margin-top: 20px;
    font-size: 2em;
    font-weight: bold;
}

.BlogTopOfPage ul{
    list-style-type: none;
    padding: 0;
}

.BlogTopOfPage li{
    margin:20px 0;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

li:hover{
    transform: scale(1.05);
}

.BlogTopOfPage li h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
    font-weight: bold;
}

.BlogTopOfPage li p{
    font-size: 1.1em;
    color:white;
}

a{
    text-decoration: none;
    color: inherit;
}

.FullBlogPostPage{
    max-width: 1200px;
    margin: 10px auto;
    padding:20px;
    font-family: Arial, sans-serif;
  /*  border-radius: 8px;
    border: 2px solid   dimgray;*/
    box-shadow: 0 30px 30px 30px rgba(0,0,0, 0.25);
}

.FullBlogPostPage h1{
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
}

.FullBlogPostPage img{
    width: 100%;
    max-height: 400px;
    object-fit: scale-down;
    margin-bottom: 20px;
    
}.FullBlogPostPage video{
    width: 50%;
    justify-items: center;
    max-height: 400px;
    object-fit: scale-down;
    
}

.FullBlogPostPage p{
    display: block;
    font-size: 1.1em;
    line-height: 1.6;
    color: #ffffff;
}
.FullBlogPostPage .author-info{
    font-size: 1em;
    color:#777;
    margin-bottom: 20px;
}

.FullBlogPostPage .content{
    margin-top: 30px;
    font-size: 1.1em;
    line-height: 1.7;
}


.loading{
    font-size: 1.5em;
    text-align: center;
    margin-top:50px;
    color: #999;
}

.blog-list{
list-style: none;
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;
    overflow-x: visible;
    padding: 10px;
    display: grid;
    grid-template-columns:repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
    justify-content: center;
    justify-items: center;
}

@media (max-width: 768px){
    .blog-list{
        grid-template-columns: 1fr;
    }
}

.blog-list .BlogTopOfPage{
    flex: 1 1 300px;
    max-width: 300px;
    box-sizing: border-box;
    margin: 20px;
}

.blog-list li{
    margin: 20px;
    text-align: center;
    width: 100%;
    max-width: 100%;
    background: #524f4f;
    border: 2px solid #ccc;;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}

.blog-list li:hover{
    transform: translateY(-5px);
}

.blog-list h2{
    text-decoration: none;
    color: #cecece;
    font-size: 1.5em;
}

.preview-image{
    display: block;
    width: auto;
    max-width: 300px;
    height: auto;
    max-height: 150px;
    border-radius: 5px;
    margin: 0 auto;
}

.BlogPostPage{
    display: flex;
    align-items: center;
    max-width: 20px;
}
