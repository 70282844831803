body{
    font-family: 'Roboto Light', sans-serif;
    color: #e5e5e5;
    background-color: #2c2c2c;
    overflow-x: hidden;
}

.HomePagecard{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
}

@media (max-width: 768px){
    .HomePagecard{
        grid-template-columns: 1fr;
    }
}


.HomePagecard-card{
    background: #333;
    padding: 5px;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
    color: white;
    width: 80%;
    transition: transform 0.2s ease, box-sahdow 0.2s ease;
    margin: 20px auto;
}

.TopOfPage{
    padding-top: 10px;
    text-decoration: none;
    text-align: center;
    color: white;
    margin-bottom: 15px;
    border-bottom: white;
}

.HomePagecard-card:hover{
    transform: scale(1.10);
    box-shadow: 0px 8px 16px rgba(0,0,0,0.3);
}

button{
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
}

button:hover{
    transform: scale(1.10);
    box-shadow: 0px 8px 16px rgba(0,0,0,0.3);
}

    /*Making Sure Links Dont Change Colour of The Text Nor The Text Style*/

    a{
        color: inherit;
        text-decoration: none;
    }

    .version-info{
        text-align: center;
        text-space: 2px;
        opacity: 50%;
        box-shadow: 0 6px 10px black ;
        padding: 2px;
        font-weight: normal;
        width: 80%;
        margin: 20px auto;
        border-radius: 10px;
    }
    
    .version-info:hover{
        box-shadow: 0 20px 10px black ;
    }


.social-links{
    padding: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
}

.social-links a img{
    width: 25px;
    height: 25px;
}

.social-links a img:hover{
    transform: scale(1.1);
}

.ProfilePicContainer{
     display: flex;
    justify-items: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
    padding: 30px;
}

.ProfilePic {
   width: 25%;
    height: 30%;
    object-fit: fill;
    margin-right: 15px;
    border-radius: 50px;
    box-shadow: 0 10px 50px rgba(0,0,0,01);
    
}

.pageOverlay{
    position: fixed;   
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    color: white;
    pointer-events: none;
}

 .overlayMessage{
     text-align: center;
     font-size: 24px;
     font-weight: bold;
     color: white;
     padding: 20px;
     background: rgba(0,0,0, 0.6);
     border-radius: 10px;
     max-width: 80%;
     transition: opacity 0.5s ease-in-out;
     animation: fadeIn 1s ease-in-out;
 }
 
 @keyframes weirdRotation {
     0%{
         transform: rotateY(0deg) rotateX(0deg);
     }
     
     50%{
         transform: rotateY(180deg) rotateX(180deg);
     }
     100% {
         transform: rotateY(360deg) rotateX(360deg);
     }
 }
 
 .copyright p{
    text-align: center;
 }
 .copyright:hover{
     animation: weirdRotation 3s ease-in-out infinite;
 }
 
 .overlayMessage p{
     opacity: 50%;
 }
 
 @keyframes fadeIn {
     from {
         opacity: 0;
         transform: translateY(20px);
     }
     to{
         opacity: 1;
         transform: translateY(0);
     }
 }
 
 .blogErrorMessage{
     padding-bottom: 15px;
     padding-top: 5px;
     justify-content: center;
     margin-bottom: 15px;
     margin-top: 20px;
     text-align: center;
     border-radius: 50px;
     box-shadow: 0 2px 10px 10px rgba(0, 0, 0, 0.5);
 }

 .blogErrorPostMessage{
     padding-bottom: 15px;
     padding-top: 5px;
     justify-content: center;
     margin-bottom: 5px;
     margin-top: 20px;
     text-align: center;
 }


 