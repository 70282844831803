

.projects{
    gap: 50px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 10px;
    display: grid;
    grid-template-columns:repeat(auto-fill, minmax(500px, 1fr));
    justify-items: center;
    align-items: center;
    max-width: 100%;
}

@media (max-width: 768px){
    .projects{
        grid-template-columns: 1fr;
    }
}

.projects-card{
    background: #333;   
    padding: 10px;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
    color: white;
    transition: transform 0.5s ease, box-shadow 0.2s ease;
    margin-bottom: 20px;
    width: 75%;
    box-sizing: border-box;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
}

.projects-card img{
        width: 100%;
        height: 150px;
        max-height: 200px;
        border-radius: 5px;
        margin-bottom: 20px;
        object-fit: cover;
    }


.projects-card:hover{
    transform: scale(1.03);
    box-shadow: 0 8px 20px;
}